import { derived, get, writable, type Readable } from 'svelte/store';
import { locale, waitLocale } from 'svelte-i18n';
import { defaultLocale } from '$lib/i18n';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { base } from '$app/paths';

// import dayjs locale
import 'dayjs/locale/en';
import 'dayjs/locale/ko';

// https://day.js.org/docs/en/display/format#list-of-localized-formats
dayjs.extend(localizedFormat);

export type MasterState = {
    locale: string;
    paramLocale: string;
    transformUrl: (url: string) => string;
};

const initialValue = {
    locale: defaultLocale,
    paramLocale: '',
};

async function applyLocaleRender() {
    const userLocale = get(masterState).locale;
    const paramLocale = get(masterState).paramLocale;
    const selectedLocale = paramLocale || userLocale || defaultLocale;
    await locale.set(selectedLocale);
    dayjs.locale(selectedLocale);
    await waitLocale();
}

export const masterState = writable(initialValue);
export const master: Readable<MasterState> = derived([masterState], ([$m], set) => {
    set({
        ...$m,
        transformUrl,
    } as MasterState);
});

export const setLocale = async (lang?: string) => {
    masterState.update(state => ({
        ...state,
        locale: lang || defaultLocale,
    }));
    await applyLocaleRender();
};

export const setParamLocale = async (lang?: string) => {
    masterState.update(state => ({
        ...state,
        paramLocale: lang || '',
    }));
    await applyLocaleRender();
};

export const transformUrl = (url: string) => {
    const locale = get(masterState).paramLocale;

    // 절대 경로인 경우 base 붙이기
    if (url.startsWith('/')) {
        if (!locale) return base + url;
        if (url.startsWith(`/${locale}`)) return base + url;
        return `${base}/${locale}${url}`;
    }

    // 상대 경로인 경우 base, locale 붙이지 않음
    return url;
};
